html {
  --color-bg: #f0f8ff;
  --color-primary: #19b0ea;
  --color-primary-light: #88deff;
  --color-primary-text: #e7e7e7;
  --color-primary-dark: #1f0f6c;
  --color-primary-dark-shadow: rgba(31, 15, 108, 0.52);
  --wheel-color: white;
  --stroke-color: #49bdea;

  --width-all-content: 1650px;
  --width-nav-bar: 1280px;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
