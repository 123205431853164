.posts-wrapper {
  position: relative;
  display: table;
  padding-bottom: 10px;
  width: 100%;
}

.load-next-button {
  display: block;
  margin: 10px auto;
}

.post-head-date {
  display: none;
  position: absolute;
  font-weight: 500;
  font-size: 1.1em;
  left: -220px;
  width: 180px;
  text-align: right;
  margin-top: 8px;
}

.posts-timeline {
  width: 2px;
  background-color: #a0b2b8;
  height: 100%;
  display: block;
  position: absolute;
  margin-left: 19px;
  margin-top: 10px;
}

.post-timeline-event {
  width: 100%;
  display: table-row;

  .post-timeline-icon {
    width: 40px;
    text-align: center;
    display: table-cell;
    padding-bottom: 15px;
    margin-top: 10px;

    .post-timeline-icon-wrapper {
      background-color: var(--color-bg);
      position: relative;
      width: inherit;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .post-timeline-body {
    display: table-cell;
    padding-bottom: 15px;

    .post-block {
      margin-top: 0;
    }
  }
}

@media (min-width: 960px) {
  .post-head-date {
    display: inline-block;
  }
}

@media (min-width: 960px) and (max-width: 1400px) {
  .post-head-date {
    left: -71px;
  }

  .load-next {
    padding: 0 0 20px 40px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1650px) {
  .post-head-date {
    left: -111px;
  }
}

@media not (max-width: 1650px) {
  .post-date-wrapper {
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    padding-bottom: 15px;
    margin-top: 10px;
  }

  .post-head-date {
    transform: rotateZ(-90deg);
    text-align: center;
    width: 180px;

    .post-head-date-text {
      background-color: var(--color-bg);
      display: inline-block;
      padding: 0 10px;
    }
  }
}

@media (min-width: 1400px) {
  .post-timeline-icon {
    position: absolute;
    left: -40px;
  }

  .posts-timeline {
    position: absolute;
    left: -40px;
  }
}

.load-next {
  padding: 0 0 20px;
  text-align: center;
}