.post-block {
  cursor: default;
  overflow: hidden;
  margin-top: 15px;
  border-radius: 0 !important;

  .post-head {
    transition: background-color .5s ease, color 250ms ease;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    color: #3e3e3e;
    text-shadow: .5px .5px .5px #656565;
    border-bottom: 1px solid var(--color-primary-dark);
    width: 100%;
    padding: 8px 16px;
    background-color: var(--color-primary);
    box-shadow: -3px 3px 9px -2px var(--color-primary-dark-shadow);

    .coin-icon {
      color: gold;
      border-radius: 50%;
      margin-left: 10px;
      margin-bottom: -5px;
    }


    .post-head-title {
      display: inline-block;
      z-index: 1;
    }

    .post-head-commercial {
      vertical-align: middle;
      text-align: right;
    }

    .post-head-username {
      width: 85%;
      vertical-align: middle;
      text-align: right;
      font-weight: 400;
      font-size: 1.1em;
    }

    h3 {
      margin: 0;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  .post-footer {
    padding: 10px 20px 7px 20px;
    display: flex;
    align-items: center;
    min-height: 60px;
    position: relative;

    .share-icon {
      text-align: end;
    }
  }

  .post-body {
    margin: 8px 16px 0;
    overflow: hidden;
    min-height: 100px;
  }

  .post-body-minified {
    max-height: 400px;
  }

  .post-body-minified ~ .post-footer {
    box-shadow: 0px -5px 10px 5px white
  }

  .post-item p {
    margin: 0;
  }

  .post-item img {
    max-width: 100%;
    max-height: 100%;
  }

  .less-more-button {
    cursor: pointer;
    text-align: center;
    width: 100%;

    .footer-fab {
      background-color: var(--color-primary);
    }
  }

  .post-date-coin {
    text-align: right;
  }

  .post-date-hidden {
    background-color: var(--color-primary-dark);
    color: var(--color-primary-text);
    padding: 1px 16px;
    height: 27px;
  }
}

.posts-wrapper {
  .post-block:hover .post-head,
  .post-block:hover .coin-icon,
  .less-more-button:hover .footer-fab {
    background-color: var(--color-primary-dark);
    color: var(--color-primary-text);
  }
}

section {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.87) !important;
  margin: 0 !important;
  width: 100% !important;
  font-size: 95% !important;
  font-weight: 400 !important;
  font-style: inherit !important;
}

@media (min-width: 960px) {
  .post-block {
    border-radius: 4px !important;;
  }

  .post-date-hidden {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .post-date-hidden .post-date-coin {
    display: none !important;
  }
  .post-wrapper .post-date-hidden .post-date-coin {
    display: none !important;
  }
}

.post-wrapper .post-date-hidden {
  display: flex !important;
}

.post-wrapper .post-link-to-page {
  display: none;
}

.post-link-to-page {
  color: black;
  text-decoration: none;
}