.center-aligned-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.left-aligned-image {
  display: block;
  margin-right: auto;
}

.right-aligned-image {
  display: block;
  margin-left: auto;
}

.small-sized-image {
}

.full-sized-image {
  width: 100%;
}

.post-item-video {
  width: 100%;
  height: calc(900% / 16);
}

.post-item-video > * {
  width: 100%;
  height: 100%;
}
